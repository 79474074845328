import { toBoolean } from '../lib/utils';

$(document).on('turbolinks:load', function(e) {
  // 要素を表示
  function showElement($target) {
    $target.removeClass('hidden');
    $target.attr('aria-hidden', 'false');
  }

  // 要素を非表示
  function hideElement($target) {
    $target.addClass('hidden');
    $target.attr('aria-hidden', 'true');
  }

  $('input[type="radio"][data-selector="customerCorporation"]').on('change', function(e) {
    const $this = $('input[type="radio"][data-selector="customerCorporation"]:checked');
    const f = toBoolean($this.val());
    if (f) {
      showElement($('[data-selector="corporationOnly"]'));
      $('[data-selector="corporationOnly"]').find('input').prop('disabled', false);
    } else {
      hideElement($('[data-selector="corporationOnly"]'));
      $('[data-selector="corporationOnly"]').find('input').prop('disabled', true);
    }
  }).trigger('change');
});
